import { DateTime } from 'luxon';

export interface DateFormat {
    weekday?: 'long' | 'short' | undefined;
    year: 'numeric' | '2-digit' | undefined;
    month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
    day: 'numeric' | '2-digit' | undefined;
    hour?: 'numeric' | '2-digit' | undefined;
    minute?: 'numeric' | '2-digit' | undefined;
}

export const formatDate = (date: string | null | undefined, hideHours: boolean, lang: string | undefined) => {
    if (!date) return null;

    const locale = lang || navigator.language.slice(0, 2);

    const dateFormat: DateFormat = {
        weekday: undefined,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
    };

    if (hideHours) {
        dateFormat['hour'] = undefined;
        dateFormat['minute'] = undefined;
    }
    const dt = DateTime.fromSQL(date);
    const dtLocale = dt.setLocale(locale).toLocaleString(dateFormat);

    return dtLocale[0].toUpperCase() + dtLocale.slice(1);
};

export const formatCurrency = (number: number | null, currency: string, lang: string | undefined) => {
    const langCurrency = lang || navigator.language.slice(0, 2);

    if (!number) {
        const newNumber = 0;
        return newNumber.toLocaleString(langCurrency, { style: 'currency', currency: currency });
    }
     
    return number.toLocaleString(langCurrency, { style: 'currency', currency: currency });
};

export const getLocalStorage = (key: string): any => {
    if (!key) return null;
    const storage = localStorage.getItem("esqOrganizer");
    if (!storage) return null;
    const storageObj = JSON.parse(storage);
    return storageObj[key];
};

export const setLocalStorage = (key: string, value: any): void => {
    const storage = localStorage.getItem("esqOrganizer");
    const storageObj = storage ? JSON.parse(storage) : {};
    storageObj[key] = value;
    localStorage.setItem("esqOrganizer", JSON.stringify(storageObj));
};