import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { Icon } from "./Icon";

import { UserState } from "types/user";
import { getLocalStorage, setLocalStorage } from "lib/functions";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { trackInstallAndroidGA } from "hooks/useGA";
interface InstallationPromptProps {
  deferredPrompt: any | null;
  clearDeferredPrompt: () => void;
  user: any | undefined;
}

export const InstallationPrompt: React.FC<InstallationPromptProps> = (
  props
) => {
  const { deferredPrompt, clearDeferredPrompt, user } = props;

  const { t } = useTranslation("common");

  const installAsPWA = async () => {
    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();

    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === "accepted") {
      trackInstallAndroidGA();
      //console.log("User accepted the install prompt.");
    }
    // else if (outcome === "dismissed") {
    //   console.log("User dismissed the install prompt");
    // }

    clearDeferredPrompt();
  };

  const cancelInstall = () => {
    clearDeferredPrompt();
  };

  if (!deferredPrompt || !user) return null;

  return (
    <Prompt>
      <div className="prompt-actions-install">
        <Button type="button" styling="ghost" onClick={cancelInstall}>
          {t("install.android_cancel")}
        </Button>
        <Button type="button" onClick={installAsPWA}>
          {t("install.android_install")}
        </Button>
      </div>
    </Prompt>
  );
};

interface AddToHomeScreenPromptProps {
  isIOS: boolean;
  user: any | undefined;
}

export const AddToHomeScreenPrompt: React.FC<AddToHomeScreenPromptProps> = (
  props
) => {
  const { isIOS, user } = props;

  const { t } = useTranslation("common");

  const [promptSeenAt, setPromptSeenAt] = useState<DateTime | null>(null);

  const today = DateTime.now().startOf("day");

  useEffect(() => {
    const ls = getLocalStorage("lastPrompt");
    if (ls) {
      setPromptSeenAt(DateTime.fromISO(ls));
    }
  }, []);

  const closePrompt = () => {
    setPromptSeenAt(today);
    setLocalStorage("lastPrompt", today.toISODate());
  };

  // Don't show is not on iOS or no user
  if (!isIOS || !user) return null;

  // Do not show is prompt is already seen this day.
  if (promptSeenAt && promptSeenAt.hasSame(today, "day")) return null;

  return (
    <Prompt>
      <div className="close-prompt-box" onClick={closePrompt}>
        <Icon name="icon-close" />
      </div>
      <div className="prompt-actions-ios">
        <p>
          {t("install.ios_lead_1")}{" "}
          <span className="icon-box">
            <Icon name="icon-share" />
          </span>{" "}
          {t("install.ios_lead_2")}
        </p>
      </div>
    </Prompt>
  );
};

const Prompt: React.FC = (props) => {
  const { children } = props;

  const { t } = useTranslation("common");

  return (
    <StyledPrompt>
      <div className="prompt-header">
        <div className="prompt-icon">
          <img src="/icons/icon-72x72.png" />
        </div>
        <h5 className="prompt-title">{t("install.title")}</h5>
      </div>

      {children}
    </StyledPrompt>
  );
};

const StyledPrompt = styled.div`
  padding: 1rem;
  width: calc(100vw - 2rem);
  background-color: ${(props) => props.theme.colors.dark300};
  font-weight: 600;
  position: fixed;
  z-index: 9999;
  bottom: 1rem;
  left: 1rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .close-prompt-box {
    position: absolute;
    right: 0.375rem;
    top: 0.375rem;
    font-size: 1.25rem;
    color: ${(props) => props.theme.colors.dark200};
    cursor: pointer;
  }

  .prompt-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .prompt-icon {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 1rem;

      img {
        border-radius: 0.5rem;
      }
    }

    .prompt-title {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1;
      width: calc(100% - 4.5rem);
    }
  }

  .prompt-actions-install {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .prompt-actions-ios {
    p {
      text-align: center;
      line-height: 1.5;

      .icon-box {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.5rem;
        position: relative;
        bottom: -0.375rem;
        color: ${(props) => props.theme.colors.primary500};
      }
    }
  }

  @media (min-width: 60em) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .prompt-actions-install {
      min-width: 40%;
      /* height: auto; */

      button {
        margin-bottom: 0 !important;
      }
    }
  }
`;
