import { createGlobalStyle, DefaultTheme } from "styled-components";

// Define theme typings
declare module "styled-components" {
  export interface DefaultTheme {
    fontFamily: string;
    colors: {
      primary500: string;
      primary400: string;
      primary300: string;
      primary200: string;
      primary100: string;

      dark500: string;
      dark400: string;
      dark300: string;
      dark200: string;
      dark200rgb: string;
      dark100: string;

      success: string;
      warning: string;
      danger: string;

      white: string;
    };
    gradient: string;
    helpers: {
      returnColor: (color: string) => string;
    };
  }
}

// Set theme values
const defaultTheme: DefaultTheme = {
  fontFamily: `'Proxima Vara',serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  colors: {
    primary500: "#068cfc",
    primary400: "#40A6FD",
    primary300: "#54B0FD",
    primary200: "#68B9FD",
    primary100: "#81C5FD",

    dark500: "#00192D",
    dark400: "#0D2336",
    dark300: "#1B3850",
    dark200: "#62829D",
    dark200rgb: "98, 130, 157",
    dark100: "#B0CFE8",

    success: "#50DC8F",
    warning: "#FFB400",
    danger: "#FC5958",

    white: "#ffffff",
  },
  gradient:
    "linear-gradient(115deg, rgba(6,140,252,1) 40%, rgba(64,166,253,1) 100%);",
  helpers: {
    returnColor: (color: string) => {
      return color;
    },
  },
};

// Set global styles
const GlobalStyle = createGlobalStyle`

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

html, body {
    overscroll-behavior-x: none;
}

body {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  background-color: ${defaultTheme.colors.dark500};
  color:#fff;
  font-family: ${defaultTheme.fontFamily};
  line-height: 1.4;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  position:  fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-kerning: normal;
  font-optical-sizing: none;
  font-variant-ligatures: common-ligatures discretionary-ligatures;
  font-variant-numeric: proportional-nums;
}

img {
  display: block;
  height: auto;
  width: 100%;
}
   
//Icons
.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  width: 1em;
  stroke: currentColor;
  stroke-width: 0;
}
`;

export { defaultTheme, GlobalStyle };
