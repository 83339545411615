import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "redux/store";

import { AuthProvider } from "@eventsquare/uikit";
// import "@eventsquare/uikit/css";

import { AppWrapper } from "components/AppWrapper";
import { AppUserWrapper } from "components/AppUserWrapper";

import "styles/fonts.css";
import { defaultTheme, GlobalStyle } from "styles/theme";

import { Api } from "lib/api";

// import { AuthProvider as AuthProv } from "lib/AuthProvider";

import { appWithI18Next } from "ni18n";
import { ni18nConfig } from "../ni18n.config";
import { DesktopMessage } from "components/DesktopMessage";
import { useEffect, useState } from "react";
import { useGA } from "hooks/useGA";
import { EnvironmentType } from "@eventsquare/uikit/dist/helpers/Environment";

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [isClientSide, setIsClientSide] = useState<boolean>(false);

  useGA();

  useEffect(() => {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsDesktop(true);
    }
    setIsClientSide(true);
  }, []);

  if (!isClientSide) return null;

  return (
    <>
      <ThemeProvider
        theme={{
          ...defaultTheme,
        }}
      >
        <Head>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
          />
          <meta
            name="description"
            content="Track your ticket sales through the Organizer app"
          />
          {/* <meta name="keywords" content="Keywords" /> */}
          <title>EventSquare Organizer</title>
          <link rel="manifest" href="/manifest.json" />
          <link
            href="/icons/icon-16x16.png"
            rel="icon"
            type="image/png"
            sizes="16x16"
          />
          <link
            href="/icons/icon-32x32.png"
            rel="icon"
            type="image/png"
            sizes="32x32"
          />
          <link rel="apple-touch-icon" href="/apple-icon.png"></link>
          <meta name="theme-color" content={defaultTheme.colors.dark500} />
          <link rel="preload" as="image" href="/symbol-defs.svg" />
        </Head>
        <GlobalStyle />
        {isDesktop && <DesktopMessage />}
        {!isDesktop && (
          <AuthProvider
            environment={process.env.NEXT_PUBLIC_ENVIRONMENT as EnvironmentType}
            apiClient={Api}
          >
            <ReduxProvider store={store}>
              <AppUserWrapper>
                <AppWrapper>
                  <Component {...pageProps} />
                </AppWrapper>
              </AppUserWrapper>
            </ReduxProvider>
          </AuthProvider>
        )}
        <script defer src="/svgxuse.js" />
      </ThemeProvider>
    </>
  );
};

export default appWithI18Next(MyApp, ni18nConfig);
