import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAuth } from "@eventsquare/uikit";

import { useAppSelector, useAppDispatch } from "redux/store";
import { setIsTouchDevice } from "redux/slices/organizerSlice";

import { Navigation } from "components/Navigation";
import {
  InstallationPrompt,
  AddToHomeScreenPrompt,
} from "components/InstallationPrompt";

export const AppWrapper: React.FC = ({ children }) => {
  const { navigation } = useAppSelector((state) => {
    return {
      navigation: state.organizer.navigation,
    };
  });

  const { user } = useAuth();

  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  const [deferredPrompt, setDeferredPrompt] = useState<any>(undefined);
  const [isIOS, setIsIOS] = useState<boolean>(false);

  useEffect(() => {
    const beforeInstall = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstall);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstall);
    };
  }, []);

  useEffect(() => {
    if (
      (window.navigator as any).standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      setIsIOS(false);
      return;
    }

    const ua = window.navigator.userAgent;
    const webkit = !!ua.match(/WebKit/i);
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIOSDevice = isIPad || isIPhone;

    setIsIOS(isIOSDevice && webkit && !ua.match(/CriOS/i));
  }, []);

  useEffect(() => {
    const isTouch =
      "ontouchstart" in window ||
      (navigator as any).maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0;
    dispatch(setIsTouchDevice(isTouch));
  }, []);

  useEffect(() => {
    if (!user) {
      const navigatorLanguage = navigator.language.slice(0, 2);
      const supportedLanguages = ["en", "nl", "fr"];
      i18n.changeLanguage(
        supportedLanguages.includes(navigatorLanguage)
          ? navigatorLanguage
          : "en"
      );
      return;
    }
    i18n.changeLanguage(user?.language);
  }, [user?.language]);

  return (
    <StyledAppLayout activeTab={!!navigation.activeTab} id="app-layout">
      {children}
      <Navigation activeTab={navigation.activeTab} />
      <InstallationPrompt
        deferredPrompt={deferredPrompt}
        clearDeferredPrompt={() => setDeferredPrompt(null)}
        user={user}
      />
      <AddToHomeScreenPrompt isIOS={isIOS} user={user} />
    </StyledAppLayout>
  );
};

const StyledAppLayout = styled.div<{
  activeTab: boolean;
}>`
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
