import React from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import Link from "next/link";

import { ActiveTabType } from "redux/slices/organizerSlice";
import { useAppSelector } from "redux/store";

import { Icon } from "./Icon";
import { useTranslation } from "react-i18next";

interface NavigationProps {
  activeTab: ActiveTabType;
}

export const Navigation: React.FC<NavigationProps> = (props) => {
  const { activeTab } = props;
  const { event, edition } = useAppSelector((state) => {
    return {
      event: state.organizer.event,
      edition: state.organizer.edition,
    };
  });

  const { t } = useTranslation('common');

  const baseUrl = (event && edition) ? `/events/${event.id}/editions/${edition.id}` : '';


  if ( !edition || !activeTab || !event ) return null;

  return (
    <StyledNavigation>
    
        <div className="nav-inner">
          <Link
            href={baseUrl + "/"}
            className={`nav-item ${
              activeTab === "dashboard" ? "nav-item-active" : ""
            }`}
          >
            <div className="nav-item-icon">
              <Icon name="icon-dashboard" />
            </div>
            <div className="nav-item-text">{t('nav.dashboard')}</div>
          </Link>
          
          <Link
            href={baseUrl + "/orders"}
            className={`nav-item ${activeTab === "orders" ? "nav-item-active" : ""}`}
          >
            <div className="nav-item-icon">
              <Icon name="icon-orders" />
            </div>
            <div className="nav-item-text">{t('nav.orders')}</div>
          </Link>

          <Link
            href={baseUrl + "/tickets"}
            className={`nav-item ${
              activeTab === "types" ? "nav-item-active" : ""
            }`}
          >
            <div className="nav-item-icon">
              <Icon name="icon-tickets" />
            </div>
            <div className="nav-item-text">{t('nav.tickets')}</div>
          </Link>
          {!!edition.has.shows && <Link
            href={baseUrl + "/shows"}
            className={`nav-item ${activeTab === "shows" ? "nav-item-active" : ""}`}
          >
            <div className="nav-item-icon">
              <Icon name="icon-shows" />
            </div>
            <div className="nav-item-text">{t('nav.shows')}</div>
          </Link>}
          
          <Link
            href={baseUrl + "/scans"}
            className={`nav-item ${activeTab === "scans" ? "nav-item-active" : ""}`}
          >
            <div className="nav-item-icon">
              <Icon name="icon-scans" />
            </div>
            <div className="nav-item-text">{t('nav.scans')}</div>
          </Link>
        </div>
      
    </StyledNavigation>
  );
};

const StyledNavigation = styled.nav`
 
 background-color: ${(props) => props.theme.colors.dark500};

  div.nav-inner {
    padding: 0.5rem 0.5rem 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 35rem;
    margin: 0 auto;

    a.nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 4.75rem;
      text-decoration: none;
      color: ${(props) => props.theme.colors.dark200};

      &.nav-item-active {
        color: ${(props) => props.theme.colors.primary500};
      }

      .nav-item-icon {
        font-size: 1.75rem;
        width: 1.75rem;
        height: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nav-item-text {
        font-size: 11px;
        font-weight: 600;
        margin-top: 0.25rem;
        line-height: 1;
      }
    }
  }
`;
