import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppDispatch } from "redux/store";

import { UserState } from "types/user";
import { AccountState } from "types/account";
import { EventType } from "types/event";
import { EditionType } from "types/edition";
import { StatType } from "types/statistics";
import { TypeType } from "types/type";
import { ShowType } from "types/shows";
import { OrdersDataType } from "types/order";
import { ScanType } from "types/scan";
import { Api } from "lib/api";

export type ActiveTabType =
  | "dashboard"
  | "types"
  | "shows"
  | "orders"
  | "scans"
  | null;

export interface StoreState {
  isTouchDevice: boolean;

  // user: UserState | undefined;
  // userLoading: boolean;
  authenticationError: boolean;
  account: AccountState | undefined;
  navigation: {
    activeTab: ActiveTabType;
  };
  selectedShow: string | null;

  eventsList: EventType[] | null;

  event: EventType | null;
  edition: EditionType | null;

  stats: StatType[];
  selectedStat: StatType | null;

  types: TypeType[] | null;
  shows: ShowType[] | null;
  showsLoading: boolean;
  orders: OrdersDataType;
  scans: ScanType[] | null;

  orderSearchValue: string;
}

const initialState: StoreState = {
  isTouchDevice: false,

  // user: undefined,
  // userLoading: false,
  authenticationError: false,
  account: undefined,
  navigation: {
    activeTab: null,
  },
  selectedShow: null,

  eventsList: null,

  event: null,
  edition: null,

  stats: [],
  selectedStat: null,

  types: null,
  shows: null,
  showsLoading: false,
  orders: {
    ordersPage: 1,
    ordersTotal: 0,
    orders: [],
  },
  scans: null,

  orderSearchValue: "",
};

const organizerSlice = createSlice({
  name: "organizer",
  initialState: initialState,
  reducers: {
    setIsTouchDevice: (state: StoreState, action: PayloadAction<boolean>) => {
      state.isTouchDevice = action.payload;
    },
    setAuthenticationError: (
      state: StoreState,
      action: PayloadAction<boolean>
    ) => {
      state.authenticationError = action.payload;
    },
    setAccount: (
      state: StoreState,
      action: PayloadAction<AccountState | undefined>
    ) => {
      state.account = action.payload;
      state.selectedShow = null;
      state.eventsList = null;
      state.event = null;
      state.edition = null;
      state.stats = [];
      state.selectedStat = null;
      state.types = null;
      state.shows = null;
      state.orders = {
        ordersPage: 1,
        ordersTotal: 0,
        orders: [],
      };
      state.scans = null;
    },
    setActiveTab: (state: StoreState, action: PayloadAction<ActiveTabType>) => {
      state.navigation.activeTab = action.payload;
    },
    setSelectedShow: (
      state: StoreState,
      action: PayloadAction<string | null>
    ) => {
      // console.log("SELECTED SHOW : ", action.payload);
      state.selectedShow = action.payload;
    },
    setEventsList: (
      state: StoreState,
      action: PayloadAction<EventType[] | null>
    ) => {
      // TODO : check type
      state.eventsList = action.payload;
    },
    setEvent: (state: StoreState, action: PayloadAction<EventType | null>) => {
      // TODO : check type
      // console.log("EVENT : ", action.payload);
      state.event = action.payload;
    },
    setEdition: (
      state: StoreState,
      action: PayloadAction<EditionType | null>
    ) => {
      // TODO : check type
      // console.log("EDITION : ", action.payload);
      state.edition = action.payload;
      state.stats = [];
      state.selectedStat = null;
      state.types = null;
      state.shows = null;
      state.orders = {
        ordersPage: 1,
        ordersTotal: 0,
        orders: [],
      };
      state.scans = null;
    },
    setStats: (state: StoreState, action: PayloadAction<StatType[]>) => {
      state.stats = action.payload;
    },
    setSelectedStat: (
      state: StoreState,
      action: PayloadAction<StatType | null>
    ) => {
      state.selectedStat = action.payload;
    },
    setTypes: (state: StoreState, action: PayloadAction<TypeType[] | null>) => {
      // TODO : check type
      // console.log("TYPES : ", action.payload);
      state.types = action.payload;
    },
    setShows: (state: StoreState, action: PayloadAction<ShowType[] | null>) => {
      // TODO : check type
      // console.log("SHOWS : ", action.payload);
      state.shows = action.payload;
    },
    setShowsLoading: (state: StoreState, action: PayloadAction<boolean>) => {
      state.showsLoading = action.payload;
    },
    setOrders: (state: StoreState, action: PayloadAction<OrdersDataType>) => {
      // TODO : check type
      // console.log("ORDERS : ", action.payload);
      state.orders = action.payload;
    },
    setScans: (state: StoreState, action: PayloadAction<ScanType[] | null>) => {
      // TODO : check type
      // console.log("SCANS : ", action.payload);
      state.scans = action.payload;
    },
    setOrderSearchValue: (state: StoreState, action: PayloadAction<string>) => {
      state.orderSearchValue = action.payload;
    },
  },
});

export const fetchEvent = (eventId: string | null) => {
  return async (dispatch: AppDispatch) => {
    if (!eventId) {
      dispatch(setEvent(null));
      return;
    }
    try {
      const response = await Api.get(`/events/${eventId}`);
      dispatch(setEvent(response.event));
    } catch (error: unknown) {
      console.error(error);
    }
  };
};

export const fetchEdition = (editionId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await Api.get(`/editions/${editionId}`);
      dispatch(setEdition(response.edition));
      if (!!response.edition.has.shows) {
        dispatch(fetchShows(editionId));
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };
};

export const fetchShows = (editionId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setShowsLoading(true));
    try {
      const response = await Api.get(`/editions/${editionId}/shows`);
      dispatch(setShows(response.shows));
    } catch (error: unknown) {
      console.error(error);
      // dispatch(setEventError(error.response?.data));
    } finally {
      dispatch(setShowsLoading(false));
    }
  };
};

export const {
  setIsTouchDevice,
  setAuthenticationError,
  setAccount,
  setActiveTab,
  setSelectedShow,
  setEventsList,
  setEvent,
  setEdition,
  setStats,
  setSelectedStat,
  setTypes,
  setShows,
  setShowsLoading,
  setOrders,
  setScans,
  setOrderSearchValue,
} = organizerSlice.actions;

export default organizerSlice.reducer;
