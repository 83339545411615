import { useEffect, useState } from 'react';
import { InitOptions } from 'react-ga4/types/ga4';
import ReactGA from 'react-ga4';
// import { useLocation } from 'react-router-dom';
import { useRouter } from 'next/router';

export const useGA = () => {
    const GATrackerESQ = 'G-4VNV6BV6PP';

    const router = useRouter();
    ReactGA.initialize([{ trackingId: GATrackerESQ }]);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: router.pathname, location: window.location.href });
    }, [router]);
};

//

export const trackLoginGA = () => {
    ReactGA.event({
        category: "app_login",
        action: "Logged in",
        //label: "Login"
    });
};

export const trackInstallAndroidGA = () => {
    ReactGA.event({
        category: "app_install_android",
        action: "App installed after promt on Android",
        //label: "InstallAndroid"
    });
};

export const trackOpenShareModalGA = () => {
    ReactGA.event({
        category: "open_share_modal",
        action: "Share modal was openend",
        // label: "OpenShareModal"
    });
};

export const trackLogoutGA = () => {
    ReactGA.event({
        category: "app_logout",
        action: "Logged out",
        // label: "Logout"
    });
};

export const trackResendTicketsGA = () => {
    ReactGA.event({
        category: "resend_tickets",
        action: "Tickets were resend",
        // label: "ResendTickets"
    });
};