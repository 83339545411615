import React from "react";
import styled, { css } from "styled-components";

export interface ButtonProps {
  type: 'button'|'submit'|'reset';
  onClick?: () => void;
  styling?: 'ghost';
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { children, type, onClick, styling } = props;

  return <StyledButton type={type} onClick={onClick} styling={styling}>{children}</StyledButton>;
};

const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props) => props.theme.colors.primary500};
  border: 2px solid ${(props) => props.theme.colors.primary500};
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  display: block;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5rem;
  //margin-top: 2rem;
  padding: 0.75rem 1rem;
  width: 100%;
  transition: all 200ms ease;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary300};
    background-color: ${(props) => props.theme.colors.primary300};
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary300};
    outline: none;
  } 

  ${props => props.styling === 'ghost' && css`
    background-color: transparent;
    border: 1px solid ${props.theme.colors.primary500};
    color: ${props.theme.colors.primary500};

    &:hover {
      border-color: ${(props) => props.theme.colors.primary300};
      color: ${props.theme.colors.primary300};
      background-color: transparent;
    }
  `}
`;

