import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const DesktopMessage = () => {
    const {t} = useTranslation('common');

    return (
    <StyledDesktopMessage>
        <h1>{t('desktop_message.title')}</h1>
        <p className="lead">{t('desktop_message.lead')}</p>
        <img className="scan-link" alt="link to EventSquare Organizer App" src="/images/app-esq.svg"/>
        <img className="esq-logo" alt="Event" src="/images/eventsquare-logo.svg"/>
    </StyledDesktopMessage>)
};

const StyledDesktopMessage = styled.div`
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  h1 {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  p.lead {
    font-size: 1.5rem;
    max-width: 35rem;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  .scan-link {
    width: 12rem;
    margin-bottom: 3rem;
  }

  .esq-logo {
    width: 12.5rem;
  }
`;