import React from "react";

export interface IconProps {
  name: string;
  alt?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
  
  const { name, alt } = props;
  return (
    <svg className={`icon icon-${name}`} role="img">
      {!!alt && <title>{alt}</title>}
      <use
        // xlinkHref={`/symbol-defs.svg?v=${Date.now()}#${name}`}
        // xlinkHref={`/symbol-defs.svg#${name}`}
        href={`/symbol-defs.svg#${name}`}
      />
    </svg>
  );
};


