import React from "react";
import styled, { css, keyframes } from "styled-components";

interface LoaderProps {
  size?: 'large' | 'medium' | 'small';
}

export const Loader: React.FC<LoaderProps> = (props) => {
  const { size } = props;
  return (
    
    <StyledLoader size={size}>
      <div className="loader">
        <div />
        <div />
        <div />
      </div>
    </StyledLoader>
  );
};

const loadingAnimation = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div<LoaderProps>`

display: flex;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;

div.loader {
  display: block;
  position: relative;
  width: 10rem;
  height: 10rem;
  margin: 0 auto 2rem;
  animation: ${loadingAnimation} 6s linear infinite;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    animation: ${loadingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
        animation-delay: -0.15s;
        border-color: ${props => props.theme.colors.primary100} transparent transparent transparent;
    }

    &:nth-child(2) {
        animation-delay: -0.3s;
        border-color: ${props => props.theme.colors.primary300} transparent transparent transparent;
    }

    &:nth-child(3) {
        animation-delay: -0.45s;
        border-color: ${props => props.theme.colors.primary500} transparent transparent transparent;
    }
  }

  ${ props => props.size === 'large' && css`
      width: 10rem;
      height: 10rem;

      div { border-width: 0.5rem }
  `}

  ${ props => props.size === 'medium' && css`
      width: 4rem;
      height: 4rem;

      div { border-width: 0.25rem }
  `}

  ${ props => props.size === 'small' && css`
      width: 0.75rem;
      height: 0.75rem;
      margin-bottom: 0;

      div { border-width: 0.125rem }
  `}
}
`;
