import { useEffect } from "react";
import { useAuth } from "@eventsquare/uikit";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "redux/store";
import { setAccount } from "redux/slices/organizerSlice";

import { Loader } from "./Loader";

import { AccountState } from "types/account";
import styled from "styled-components";

export const AppUserWrapper = (props: { children: any }) => {
  const { children } = props;
  const { user, accountId } = useAuth();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const { account } = useAppSelector((state) => {
    return {
      account: state.organizer.account,
      eventsList: state.organizer.eventsList,
    };
  });

  useEffect(() => {
    if (!user) {
      dispatch(setAccount(undefined));
      return;
    }
    if (!account || account.id !== accountId) {
      const acc = user.accounts.find((a) => a.id === accountId);
      if (acc) {
        dispatch(setAccount(acc as unknown as AccountState));
      } else {
        dispatch(setAccount(user.accounts[0] as unknown as AccountState));
      }
    }
  }, [user, accountId]);

  if (!user || !accountId || !account) {
    return (
      <StyledLoader>
        <Loader />
      </StyledLoader>
    );
  }

  return <>{children}</>;
};

const StyledLoader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
